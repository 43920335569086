import './style.css'
import * as THREE from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import * as CANNON from 'cannon-es'

const hello = "      ___           ___                                       ___     \n" +
    "     /__/\\         /  /\\                                     /  /\\    \n" +
    "     \\  \\:\\       /  /:/_                                   /  /::\\   \n" +
    "      \\__\\:\\     /  /:/ /\\    ___     ___   ___     ___    /  /:/\\:\\  \n" +
    "  ___ /  /::\\   /  /:/ /:/_  /__/\\   /  /\\ /__/\\   /  /\\  /  /:/  \\:\\ \n" +
    " /__/\\  /:/\\:\\ /__/:/ /:/ /\\ \\  \\:\\ /  /:/ \\  \\:\\ /  /:/ /__/:/ \\__\\:\\\n" +
    " \\  \\:\\/:/__\\/ \\  \\:\\/:/ /:/  \\  \\:\\  /:/   \\  \\:\\  /:/  \\  \\:\\ /  /:/\n" +
    "  \\  \\::/       \\  \\::/ /:/    \\  \\:\\/:/     \\  \\:\\/:/    \\  \\:\\  /:/ \n" +
    "   \\  \\:\\        \\  \\:\\/:/      \\  \\::/       \\  \\::/      \\  \\:\\/:/  \n" +
    "    \\  \\:\\        \\  \\::/        \\__\\/         \\__\\/        \\  \\::/   \n" +
    "     \\__\\/         \\__\\/                                     \\__\\/    "

console.log(hello)
let location = 'nav-home'
/*
THREE JS
 */

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Sound
 */
const hitSound = new Audio('/sounds/hit.mp3')
const playHitSound = (collision) => {
    const impactStrength = collision.contact.getImpactVelocityAlongNormal()
    if(impactStrength > 1.5) {
        const volume = (impactStrength - 1.5) / (10 - 1.5)   // 10 I guessed as a good max
        hitSound.volume = volume > 1 ? 1 : volume
        // Reset the sound if its already playing for new events
        hitSound.currentTime = 0
        hitSound.play()
    }
}

/**
 * Textures
 */
const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/3/px.png',
    '/textures/environmentMaps/3/nx.png',
    '/textures/environmentMaps/3/py.png',
    '/textures/environmentMaps/3/ny.png',
    '/textures/environmentMaps/3/pz.png',
    '/textures/environmentMaps/3/nz.png'
])

/**
 * Physics
 */
const world = new CANNON.World()
world.gravity.set(0, -9.82, 0)
world.broadphase = new CANNON.SAPBroadphase(world)
// Sleep non moving objects until they are interacted with again
world.allowSleep = true

// Materials
const defaultMaterial = new CANNON.Material('default')
const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: .5, // Lower friction = slide
        restitution: .6 // Degredation of inertia (Bounciness)
    }
)

world.addContactMaterial(defaultContactMaterial)
world.defaultContactMaterial = defaultContactMaterial

// Floor
const floorShape = new CANNON.Plane()
const floorBody = new CANNON.Body()
floorBody.mass = 0
floorBody.position.set(5,-5,-5)
floorBody.addShape(floorShape)
// Hard way, see easy way below
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI * .5)
world.addBody(floorBody)


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = -7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = -7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)

/**
 * Utils
 */
const objectsToUpdate = []

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 2, 10)
scene.add(camera)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


const clearCanvas = () => {
    for (let object of objectsToUpdate) {
        object.body.removeEventListener('collide', playHitSound)
        world.removeBody(object.body)
        scene.remove(object.mesh)
    }
    objectsToUpdate.splice(0, objectsToUpdate.length)
}

/**
 * Create letter
 */
const letterMaterial = new THREE.MeshStandardMaterial({
        metalness: 0.1,
        roughness: 0.8,
        envMap: environmentMapTexture
    }
)
let font = null
const fontLoader = new FontLoader();
const fontUrl = "https://raw.githubusercontent.com/AlainBarrios/Fonts/refs/heads/master/Droid%20Sans_Regular.json";
fontLoader.load(fontUrl, (data) => { font = data});
// ==> Create Text
const createLetter = function(letter) {
    if(!font || location !== 'nav-home') return
    const widthMultiplier = window.innerWidth < 1000 ? 5 : 20
    const position = {
        x: (Math.random() - .5) * widthMultiplier,
        y: 10,
        z: -Math.abs((Math.random() - .5) * widthMultiplier)
    }
    const geometry = new TextGeometry(
        letter,
        {
            font: font,
            size: 2,
            height: 1,
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: .01,
            bevelSize: .1,
            bevelOffset: 0,
            bevelSegments: 5
        }
    );

    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();

    const mesh = new THREE.Mesh(geometry, letterMaterial);
    mesh.size = mesh.geometry.boundingBox.getSize(new THREE.Vector3());
    mesh.position.copy(position)
    scene.add(mesh)


    const box = new CANNON.Box(new CANNON.Vec3().copy(mesh.size).scale(.5));
    mesh.body = new CANNON.Body({
        mass: 100,
        position: new CANNON.Vec3(0, 3, 0),
        shape: box,
        material: defaultMaterial
    });
    const { center } = mesh.geometry.boundingSphere;

    const body = mesh.body
    body.addShape(box, new CANNON.Vec3(center.x, center.y, center.z));
    body.position.copy(position)
    body.addEventListener('collide', playHitSound)
    world.addBody(body);
    objectsToUpdate.push({mesh, body})
};

/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0
const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const timeDelta = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    // Update physics world (Params for step below)
    // 1. Fixed time step (Framerate),
    // 2. Time since last step,
    // 3. How many iterations world can apply to catch up with delay
    world.step(1 / 60, timeDelta, 3)

    for (let object of objectsToUpdate) {
        object.mesh.position.copy(object.body.position)
        object.mesh.quaternion.copy(object.body.quaternion)
    }
    // Update controls
    //controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

function onWindowResize() {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

}

window.addEventListener('resize', onWindowResize, false)

const notficationTimeout = setTimeout(() => {
    $('#notification').css('visibility', 'visible')
}, 5000)

const removeNotification = () => {
    $('#notification').css('visibility', 'hidden')
    if(notficationTimeout) {
        clearTimeout(notficationTimeout)
    }
}
let capsOn = false;
let shiftHeld = false;
const ignoredMatcher = /(\w|\d)/g
const stackedMatcher = /\d/g
var getKey = (pressed) => {
    var data = {}
    if(pressed.match(stackedMatcher)) {
        pressed = pressed.match(stackedMatcher).pop()
    }
    switch(pressed){
        case '':
        case ' ':
            data.key = 'space'
            data.id = 'space'
            break;
        case '!':
            data.key = pressed
            data.id = '1'
            break;
        case '@':
            data.key = pressed
            data.id = '2'
            break;
        case '#':
            data.key = pressed
            data.id = '3'
            break;
        case '$':
            data.key = pressed
            data.id = '4'
            break;
        case '%':
            data.key = pressed
            data.id = '5'
            break;
        case '^':
            data.key = pressed
            data.id = '6'
            break;
        case '&':
            data.key = pressed
            data.id = '7'
            break;
        case '*':
            data.key = pressed
            data.id = '8'
            break;
        case '(':
            data.key = pressed
            data.id = '9'
            break
        default:
            data.key = pressed
            data.id = pressed
    }
    if(!data.key.match(ignoredMatcher)) {
        data.id = 'ignored'
    }
    if(capsOn || shiftHeld) data.key = data.key.toUpperCase()
    data.id = data.id.toLowerCase()
    return data
}
$(document).keydown((e) => {
    removeNotification()
    $('#'+ getKey(e.key).id).closest('.key').addClass('pressed');
    shiftHeld = e.shiftKey
})
$(document).keyup((e) => {
    let data = getKey(e.key)
    if(data.key.toLowerCase() === 'capslock'){
        if (!capsOn && event.getModifierState("CapsLock")) {
            capsOn = true;
        } else {
            capsOn = false;
        }
    }
    $('#' + data.id.toLowerCase()).closest('.key').removeClass('pressed');
    createLetter(data.key)
    shiftHeld = e.shiftKey
})
// Do the keypress
$('.key > div').mousedown(function(){
    removeNotification()
    $(this).closest('.key').addClass('pressed');
}).mouseup(function(){
    $(this).closest('.key').removeClass('pressed');
    createLetter(getKey(($(this).closest('.key').children()[0].innerText)).key)
});

// Nav states
var nav_locked = false;
function hide_mobile_nav(){
    $('.mobile-nav').attr('data-state', 'closed');
    $('.mobile-nav').css('position', 'absolute');
    $('.nav.mobile-open').removeClass('mobile-open');
    $('.nav').css('opacity', 0);
    $('.mobile-nav').removeClass('change');
}

$('.toggle').click(function(){
    location = this.id
    $('.toggle').each(function(){
        $(this).find('.indicator').addClass('hidden');
    })
    $(this).find('.indicator').removeClass('hidden');

    switch(this.id){
        case 'nav-home':
            $('#about').addClass('hidden');
            $('#projects').addClass('hidden');
            $('#home').removeClass('hidden');
            break;
        case 'nav-projects':
            clearCanvas()
            $('#about').addClass('hidden');
            $('#home').addClass('hidden');
            $('#projects').removeClass('hidden');
            break;
        case 'nav-about':
            clearCanvas()
            $('#projects').addClass('hidden');
            $('#home').addClass('hidden');
            $('#about').removeClass('hidden');
            break;
        default:
            break;
    }
    if($('.nav.mobile-open').length > 0){
        hide_mobile_nav();
        nav_locked = false;
    }
});


$('.mobile-nav').on('touchend click', function(){
    if(!nav_locked){
        nav_locked = true;
        if($(this).attr('data-state') == 'closed'){
            $(this).attr('data-state', 'open');
            $(this).addClass('change');
            $('#nav-open').addClass('hidden');
            $('.nav').addClass('mobile-open');
            $('.nav').animate({'opacity': '1'}, 500);
        } else {
            hide_mobile_nav();
        }
        setTimeout(function(){nav_locked = false;}, 500);
    }
});
